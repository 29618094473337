import React, { useEffect, useState } from "react";
import FullCalendar, { ViewApi } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Oval } from "react-loader-spinner";
import { pathToRegexp } from "path-to-regexp";
import { push } from "connected-react-router";
import { toISO, setZero, fetchWordForDate } from "../../lib/utils";
import { Verified, NotVerified } from "../../components/FormState";
import Loadingstate from "../../components/LoadingState";

import CalendarActions, {
  calendarSelector,
  CalendarView,
  CalendarViewTypes,
} from "../../redux/calendar";
import AuthActions from "../../redux/auth";

import "./calendar.css";
import { firestore } from "firebase";

const mapStateToProps = (state: any) => ({
  calendarView: state.calendar.calendarView,
  date: state.calendar.date,
  creatingWord: state.calendar.creatingWord,
  wordCreationError: state.calendar.wordCreationError,
  pathname: state.router.location.pathname,
  defaultDate: state.calendar.defaultDate,
  words: state.calendar.words,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  calendarActions: bindActionCreators(CalendarActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch),
  push: (route: string) => dispatch(push(route)),
});

const getDaysArray = function () {
  const now = setZero(new Date());
  const oneYear = new Date(now.setFullYear(now.getFullYear() + 1));

  for (
    var arr = [], dt = setZero(new Date());
    dt <= oneYear;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

const BackButton = ({ onClick }: any) => (
  <button
    onClick={onClick}
    className="absolute left-0 bg-gray-200 hover:bg-gray-200 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 hover:bg-gray-200"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        strokeWidth="2"
        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
      />
    </svg>
  </button>
);

const emptyEvents = (): string[] => {
  const daylist = getDaysArray();
  const toIso = daylist.map((v) => toISO(setZero(v)));
  return toIso;
};

const MakeWordleForm = ({
  onBack,
  date,
  creatingWord,
  calendarActions,
  wordCreationError,
  edit,
  user,
}: any) => {
  const [word, setWord] = useState("");
  const [note, setNote] = useState("");
  const [wordValid, setWordValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPreset = async () => {
    // setLoading(true);
    const existing = await fetchWordForDate(user, date);
    setWord(existing.data().word);
    setNote(existing.data().note);
    // setLoading(false);
  };

  useEffect(() => {
    if (edit) {
      fetchPreset();
    }
  }, [edit]);

  useEffect(() => {
    if (wordCreationError) calendarActions.setWordCreationError("");
    setWordValid(word.length >= 4);
  }, [word]);

  const handleSave = () => {
    calendarActions.createWord(word, note, date);
  };

  if (loading)
    return (
      <div
        style={{
          height: "40vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Oval color="#579e6e" height={50} width={50} />
      </div>
    );

  return (
    <div>
      <div>
        <div>
          <div className="flex justify-center items-center relative mb-6">
            <BackButton onClick={onBack} />
            <h3 className="text-xl font-bold leading-tight">
              Worble for{" "}
              {date?.toLocaleString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </h3>
          </div>
        </div>
        <form>
          <div className="mb-4">
            <label
              htmlFor="word"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Word (4 letters or more)
            </label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <div className="absolute" style={{ top: 7, right: 8 }}>
                {wordValid ? <Verified /> : <NotVerified />}
              </div>
              <input
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z]/gi, "");
                  setWord(value);
                }}
                id="word"
                required
                value={word}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="note"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Note to followers (optional)
            </label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <div className="absolute" style={{ top: 7, right: 8 }}>
                {/* {<Verified />} */}
              </div>
              <textarea
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                id="note"
                required
                value={note}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        </form>

        <button
          disabled={creatingWord || !wordValid}
          onClick={handleSave}
          className={"button-primary"}
        >
          {creatingWord ? (
            <Oval color="white" height={24} width={24} />
          ) : (
            "Save"
          )}
        </button>
        {wordCreationError && (
          <p className="px-6 mt-6 text-red-500 text-xs italic">
            {wordCreationError}
          </p>
        )}
      </div>
    </div>
  );
};

const pathToDate = (pathname: any) => {
  if (pathname.split("/").slice(-1).find(Boolean) === "2022-03-13") {
    const x = new Date(2022, 2, 13);
    return x;
  }

  try {
    const d = new Date(
      new Date(pathname.split("/").slice(-1).find(Boolean)).toLocaleString(
        "en-US",
        { timeZone: "America/New_York" }
      )
    );

    if (pathname.split("/").slice(-1).find(Boolean) !== "2022-03-13") {
      d.setDate(d.getDate() + 1);
      d.setUTCHours(0, 0, 0, 0);
    }

    return d;
  } catch (err) {
    console.error(err);
    return new Date();
  }
};

function waitForElement(selector: any) {
  return new Promise(function (resolve, reject) {
    var element = document.querySelector(selector);

    if (element) {
      resolve(element);
      return;
    }

    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        var nodes: any[] = Array.from(mutation.addedNodes);
        for (var node of nodes) {
          if (node.matches && node.matches(selector)) {
            observer.disconnect();
            resolve(node);
            return;
          }
        }
      });
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

function mergeSortedArray(a: any, b: any) {
  var tempArray = [];
  while (a.length || b.length) {
    if (typeof a[0] === "undefined") {
      tempArray.push(b[0]);
      b.splice(0, 1);
    } else if (a[0].data().date > new Date(b[0])) {
      tempArray.push(b[0]);
      b.splice(0, 1);
    } else {
      tempArray.push(a[0]);
      a.splice(0, 1);
    }
  }
  /*
else if (toISO(a[0]?.data()?.date) === b[0]?.date) {
        tempArray.push(a[0]);
        a.splice(0,1);
        b.splice(0,1)
      }
  */

  for (let i = 0; i < tempArray.length; i++) {
    if (!tempArray[i].start) {
      tempArray[i].start = toISO(tempArray[i].data().date);
      tempArray[i].title = tempArray[i].data().word;
    }
  }

  const uniqify = (array: any, key: any) =>
    array.reduce(
      (prev: any, curr: any) =>
        prev.find((a: any) => a[key] === curr[key])
          ? prev
          : prev.push(curr) && prev,
      []
    );
  const uniqued = uniqify(tempArray, "start");
  return uniqued;
}

const Calendar = ({
  calendarView,
  date,
  creatingWord,
  calendarActions,
  wordCreationError,
  pathname,
  push,
  defaultDate,
  words,
  authActions,
  user,
}: any) => {
  const [events, setEvents] = useState(
    emptyEvents().map((e: any) => ({ start: e, title: "No word selected" }))
  );
  const [eventsWithWords, setEventsWithWords] = useState([]);

  const [pathDate, setPathDate] = useState(pathToDate(pathname));

  const dispatch = useDispatch();
  const calendarComponentRef = React.useRef();

  const createWorble = (date: Date) => {
    dispatch({
      type: CalendarViewTypes.SET_CALENDAR_VIEW,
      calendarView: CalendarView.CREATE_WORBLE,
      date,
    });
  };

  const editWorble = (date: Date) => {
    dispatch({
      type: CalendarViewTypes.SET_CALENDAR_VIEW,
      calendarView: CalendarView.EDIT_WORBLE,
      date,
    });
  };

  useEffect(() => {
    setPathDate(pathToDate(pathname));
  }, [pathname]);

  useEffect(() => {
    if (!calendarComponentRef?.current || !defaultDate) return;

    let calendarApi = (calendarComponentRef.current as any).getApi();
    calendarApi.gotoDate(defaultDate);
  }, [defaultDate, calendarComponentRef?.current]);

  useEffect(() => {
    const test = (path: string) => {
      return pathToRegexp(path).test(pathname);
    };

    switch (true) {
      case test("/words/create/:date"):
        createWorble(pathToDate(pathname));
        break;
      case test("/words/edit/:date"):
        editWorble(pathToDate(pathname));
        break;
      case test("/words/:date"):
        calendarActions.setCalendarView(
          CalendarView.CALENDAR,
          pathToDate(pathname)
        );
        break;
      case test("/words"):
        calendarActions.setCalendarView(CalendarView.CALENDAR);
        break;
      default:
        break;
    }
  }, [pathname]);

  const combine = (words: any) => {
    const wordsSorted = [...words].sort((a, b) => {
      return a.data().date - b.data().date;
    });

    const evts = emptyEvents().map((e: any) => ({
      start: e,
      title: "No word selected",
    }));

    const merged = mergeSortedArray(wordsSorted, evts);
    setEvents(merged);
  };

  useEffect(() => {
    if (!words) return;
    combine(words);
  }, [words]);

  const RenderEventContent = (eventInfo: any) => {
    const onCreateClick = () => {
      createWorble(eventInfo.event.start);
      push(`/words/create/${toISO(eventInfo.event.start)}`);
    };

    const onEditClick = () => {
      push(`/words/edit/${toISO(eventInfo.event.start)}`);
    };

    return (
      <div style={{ minWidth: 250 }}>
        {eventInfo.event.title != "No word selected" ? (
          <div className="shadow-lg">
            <h3 className="text-5xl font-bold leading-tight text-black p-8 rounded-md border-0">
              {eventInfo.event.title}
            </h3>
            <div>
              {setZero(new Date()) <= eventInfo.event.start && (
                <button
                  style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                  className="button-primary"
                  onClick={onEditClick}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <button
              onClick={onCreateClick}
              className="button-primary bg-green-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span className={"ml-2"}>Create Worble</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {calendarView === CalendarView.CALENDAR ? (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGrid"
          events={events}
          eventContent={RenderEventContent}
          initialDate={defaultDate}
          ref={calendarComponentRef as any}
          datesSet={(el) => {
            if (!window.location.pathname.includes("edit"))
              window.history.replaceState(
                {},
                document.title,
                `/words/${toISO(el.start)}`
              );
          }}
        />
      ) : calendarView === CalendarView.EDIT_WORBLE ? (
        <MakeWordleForm
          onBack={() => {
            window.history.back();
          }}
          date={pathDate}
          creatingWord={creatingWord}
          wordCreationError={wordCreationError}
          calendarActions={calendarActions}
          edit
          user={user}
        />
      ) : (
        <MakeWordleForm
          onBack={() => {
            window.history.back();
          }}
          date={pathDate}
          creatingWord={creatingWord}
          wordCreationError={wordCreationError}
          calendarActions={calendarActions}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
