import React from 'react'

import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  guesses: string[]
  currentGuess: string
  solution: string;
}

export const Grid = ({ guesses, currentGuess, solution }: Props) => {
  const empties =
    guesses.length < 5 ? Array.from(Array(5 - guesses.length)) : []

  return (
    <div className="pb-4">
      {guesses.map((guess, i) => (
        <CompletedRow key={i} guess={guess} solution={solution} />
      ))}
      {guesses.length < 6 && <CurrentRow solution={solution} guess={currentGuess} />}
      {empties.map((_, i) => (
        <EmptyRow solution={solution} key={i} />
      ))}
    </div>
  )
}
