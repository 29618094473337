import React, { useEffect, useState } from 'react';
import VerifyDNSTable from './VerifyDNSTable';
import RoutesTable from './RoutesTable';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import deepEqual from 'deep-equal';
import axios from 'axios';

import Firebase from '../../Firebase';
import Collections from '../../lib/collections';

import DomainActions from '../../redux/domains';
import LoadingState from '../../components/LoadingState';

//#38a169
const LinkOut = () => (
    <svg class="inline h-4 w-4 hover:text-green-500" style={{fill: '#38a169', marginLeft: '4px', marginBottom: '2px'}} xmlns="http://www.w3.org/2000/svg"viewBox="0 0 48 48"><path d="M38 38H10V10h14V6H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V24h-4v14zM28 6v4h7.17L15.51 29.66l2.83 2.83L38 12.83V20h4V6H28z"/></svg>
)

let unsubscribe;

const Create = ({pathname, activeDomain, domainActions}) => {

    const [loading, setLoading] = useState(true);
    const [domainName, setDomainName] = useState('');
    const [defaultSway, setDefaultSway] = useState('');

    const handleSupport = () => {
        try {
            (window).Tawk_API.maximize();
        } catch (err) {
            console.error('Unable to open Tawk API');
        }
    }

    const {
      params: { id }
    } = pathname.includes('edit') ? matchPath(pathname, {
      path: '/domains/:id/edit/',
      exact: true,
      strict: false
    }) || { params: {} }  :{ params: {} }
  

    useEffect((e) => {
      if (id) {
        domainActions.setActiveDomain(null);
        domainActions.getActiveDomain(id);
      }
    },[pathname])

    useEffect(() => {
      console.log('hdkfjlksdjflkjsdf', activeDomain)
      if (activeDomain && activeDomain.data()) {
        if (typeof unsuscribe === 'object') unsubscribe();

        Firebase.firestore()
          .collection(Collections.domains)
          .doc(activeDomain.id)
          .onSnapshot(
            doc => {
              if (!doc.data()) return
              try {
                if (!deepEqual(doc.data(), activeDomain.data())) {
                  domainActions.setActiveDomain(doc);
                }
                } catch(err) {
                  console.log(err)
                }
            },
            err => {
              console.error(err);
            })
      }
    }, [activeDomain])

    if (!activeDomain) return <LoadingState />

    return (
        <>
  <main>
  <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Review</h3>
              <p class="mt-1 text-sm leading-5 text-gray-600">
              You'll be able to add more sways once your domain is verified.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <RoutesTable activeDomain={activeDomain}/>
              </div>
            </form>
          </div>
        </div>

              
      
      <div class="hidden sm:block">
        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>
      </div>

      <div class="mt-10 sm:mt-0" style={{opacity: activeDomain ? '100%' : '50%'}}>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Verify your Domain</h3>
              <p class="mt-1 text-sm leading-5 text-gray-600">
                Follow these steps to verify your domain
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
              <div class="shadow overflow-hidden sm:rounded-md">
            <div>
              <h3 class="pt-5 px-5 text-lg leading-6 font-medium text-gray-900">
                     1. Go to your DNS Provider
                 </h3>
                <div class="px-4 py-5 bg-white sm:p-6">
                    <p class="block text-sm font-light leading-5 text-gray-700">
                    Got to the DNS Provider that you use to manage {activeDomain.data().domain} and add the following DNS records.
                    </p>
                    <p class="block py-2 text-sm font-light leading-5 text-gray-700">
                         Want a step-by-step walk through? <a onClick={(e) => {
                            e.preventDefault();
                            handleSupport()
                        }} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">Chat with support</a>
                    </p>
                    <h3 class="pt-5 text-lg leading-6 font-medium text-gray-900">
                     Common providers include:
                     </h3>
                     <p class="pt-2 block text-sm font-light leading-5 text-gray-700">
                     <a onClick={() => window.open("https://support.godaddy.com/help/article/7925/adding-or-editing-txt-records?locale=en")} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        GoDaddy
                        <LinkOut />
                    </a>
                    </p>
                    <p class="pt-2 block text-sm font-light leading-5 text-gray-700">
                     <a onClick={() => window.open("https://www.namecheap.com/support/knowledgebase/article.aspx/579/2237/which-record-type-option-should-i-choose-for-the-information-im-about-to-enter")} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        NameCheap
                        <LinkOut />
                    </a>
                    </p>
                    <p class="pt-2 block text-sm font-light leading-5 text-gray-700">
                     <a onClick={() => window.open("http://www.networksolutions.com/support/how-to-manage-advanced-dns-records/")} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                     Network Solutions
                     <LinkOut />
                    </a>
                    </p>
                    <p class="pt-2 block text-sm font-light leading-5 text-gray-700">
                     <a onClick={() => window.open("https://support.squarespace.com/hc/en-us/articles/206255268")} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        Google Domains
                        <LinkOut />
                    </a>
                    </p>
                    <p class="pt-2 block text-sm font-light leading-5 text-gray-700">
                     <a onClick={() => window.open("https://support.rackspace.com/how-to/set-up-dns-records-for-cloud-office-email-and-skype-for-business/")} href="" class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        Rackspace Email & Apps
                        <LinkOut />
                    </a>
                    </p>
                </div>
                </div>
                <div>
              <h3 class="pt-2 px-5 text-lg leading-6 font-medium text-gray-900">
                     2. Add DNS Records to link your domain to SwayDomains
                 </h3>
                 <div class="px-4 py-5 bg-white sm:p-6">
                    <p class="block text-sm font-light leading-5 text-gray-700">
                        We will check every minute for updates. When we verify that you have updated your DNS records, the red X's will become green checks.
                    </p>
                    </div>
                 <div class="md:mt-4">
                <VerifyDNSTable domain={activeDomain}/>
                </div>
                <div class="mt-5">
              <h3 class="pt-4 px-5 text-lg leading-6 font-medium text-gray-900">
                     3. Wait for your domain to verify
                 </h3>
                 <div onClick={e => e.preventDefault()} class="hidden md:block text-left px-5 pt-4"><span class="inline-flex rounded-md shadow-md"><span class="inline-flex rounded-md shadow-xs"><a href="" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white-600 hover:bg-white-500  focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">Verify DNS Settings</a></span></span></div>
                 <div class="px-4 py-5 bg-white sm:p-6">
                    <p class="block text-sm font-light leading-5 text-gray-700">
                      Once you make the above DNS changes it can take 15 minutes-24hrs for those changes to propagate (but usually takes around 15 minutes!).
                    </p>
                    </div>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
            
      <div class="hidden sm:block">
        <div class="py-5">
        </div>
      </div>
    

      </main>
      </>
    )
}

const mapDispatchToProps = (dispatch) => ({
  domainActions: bindActionCreators(DomainActions, dispatch)
});


const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  activeDomain: state.domains.activeDomain
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)