import { db } from "../public_routes/worble/firestore";
import Collections from "../lib/collections";

export const setZero = (date: Date) => {
  // date.setUTCHours(0, 0, 0, 0);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

function isDST(d: any) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export const toISO = (date: Date) => {
  var tzoffset = isDST(date)
    ? (new Date().getTimezoneOffset() - 60) * 60000
    : new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(date.getTime() - tzoffset).toISOString();

  return localISOTime.slice(0, 10);
};
export const USERNAME_REGEX = /[^a-zA-Z0-9-_]+/gi;

export const validateUserNameSync = (username: string) => {
  if (username.match(USERNAME_REGEX)) return false;
  if (username.length < 2 || username.length > 30) return false;
  return true;
};

export const validateUserName = async (username: string, user?: any) => {
  if (!validateUserNameSync(username)) return false;
  const snap = await db
    .collection(Collections.users)
    .where("username_lowercase", "==", username.toLowerCase())
    .get();

  if (snap.docs.length === 0) return true;

  if (user) {
    if (snap.docs.length === 1 && snap.docs[0].data().ref === user.ref)
      return true;
  }
  return false;
};

export const saveUsername = async (user: any, username: string) => {
  const valid = await validateUserName(username);
  if (valid) {
    await user.ref.update({
      username,
      username_lowercase: username.toLowerCase(),
    });

    await db.collection(Collections.users_public).doc(user.id).update({
      username,
      username_lowercase: username.toLowerCase(),
    });
  }
};

export const fetchWordForDate = async (user: any, date: string) => {
  const snap = await db
    .collection(Collections.words)
    .where("user", "==", user.ref)
    .where("date", "==", setZero(new Date(date)))
    .get();
  return snap.docs[0];
};
