import React from 'react'
import { BaseModal } from './BaseModal'
import logo from '../../../../hatch.png'

type Props = {
  isOpen: boolean
  handleClose: () => void
  popular: any[]
}

export const PopularModal = ({ isOpen, handleClose, popular }: Props) => {
  return (
    <BaseModal title="Recommended" isOpen={isOpen} handleClose={handleClose}>
      {/* <StatBar gameStats={gameStats} /> */}
      <div>
      {
          popular.map((pop) => {
              return (
                  <div className="flex mt-4" style={{minHeight: 50}}>
                    <img className="cursor-pointer" onClick={() => window.open(`https://${pop.data().username_lowercase}.worble.net`)} src={pop.data().avatar ? pop.data().avatar : logo} style={{
                            borderRadius: "50%",
                            width: 50,
                      }}></img>
                        <div className='flex-col space-around align-left' style={{textAlign: 'left', display: 'flex', justifyContent: 'center'}}>
                        <div onClick={() => window.open(`https://${pop.data().username_lowercase}.worble.net`)} className="font-bold hover:underline text-sm ml-4 cursor-pointer mt-1">
                                {pop.data().username_lowercase.toUpperCase()}
                         </div>
                          <div className="cursor-pointer text-sm ml-4 mt-1 hover:underline" onClick={() => window.open(`https://${pop.data().username_lowercase}.worble.net`)}>
                                {pop.data().description}
                         </div>
                         </div>
                        {/* <div className="mt-2">{pop.data().avatar}</div> */}

                  </div>
              )
          })
      }
      </div>
      {/* <Histogram gameStats={gameStats} /> */}
    </BaseModal>
  )
}
