import React from 'react';
import { Oval } from 'react-loader-spinner'

export default class App extends React.Component {
   render() {
    return(
    <div style={{height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Oval
            color="#579e6e"
            height={50}
            width={50}
            timeout={25000} //3 secs
        />
     </div>
    );
   }
}