const ROOT = '@';

const DEFAULT_PATH = '/*';

const CheckDNSStatus = {
    'active': 1,
    'successful': 2,
    'failed': 3
};

const mail = {
    'dns_successful': 'd-817856df24924203acfd85eb8c7c9bdb'
}

module.exports = {
    ROOT,
    DEFAULT_PATH,
    CheckDNSStatus,
    mail
}