import {
  all,
  takeEvery,
  call,
  put,
  fork,
  delay,
  take,
  select
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import firebase from 'firebase';
import humanId from 'human-id';
import Firebase, { reduxSagaFirebase } from '../Firebase';
import AuthActions, { AuthTypes, authSelector } from '../redux/auth';
import { CalendarViewTypes } from '../redux/calendar';
import { ENV } from '../constants';
import { PROD } from '../lib/envs';
import Collections from '../lib/collections';
import { push } from 'react-router-redux';
import { listenWords } from './WorblesSaga';
import { PostRegistrationStep } from './postRegistration';

var authProvider = new firebase.auth.OAuthProvider('microsoft.com');
var authProviderG = new firebase.auth.OAuthProvider('google.com');

function* microsoftLoginSaga() {
  try {
    const data = yield call(reduxSagaFirebase.auth.signInWithPopup, authProvider)
  }
  catch(error) {
    console.error(error);
  }
}

function* googleLoginSaga() {
  try {
    const data = yield call(reduxSagaFirebase.auth.signInWithPopup, authProviderG)
  }
  catch(error) {
    console.error(error);
  }
}

function* fetchUser(userRef) {
  for (let i = 0; i < 5; i++) {
    try {
      const userSnap = yield call([userRef, userRef.get]);
      return userSnap;
    } catch (err) {
      if (i < 4) {
        yield delay(2000);
      }
    }
  }
  throw new Error('User fetch failed');
}

function* createFirestoreUser(auth) {
  yield Firebase.firestore().collection(Collections.users).doc(auth.uid).set({
    email: auth.email,
    name: auth.displayName,
    postRegistrationStep: PostRegistrationStep.SET_USERNAME
  })

  yield Firebase.firestore().collection(Collections.users_public).doc(auth.uid).set({
    username: ''
  })
}

function* deleteUser(auth) {
  const { user } = yield select(authSelector);

  const publicUser = yield Firebase.firestore().collection(Collections.users_public).doc(user.id).get();

  yield publicUser.ref.delete();
  yield Firebase.auth().currentUser?.delete();
  window.location.href = "/"
  yield user.ref.delete();
}

function* register(action) {
  try {
    const auth = Firebase.auth()
    const result = yield call(
      [auth, auth.createUserWithEmailAndPassword],
      action.email,
      action.password
    )
    yield call(createFirestoreUser, auth.currentUser);
    yield call(setUserParameters, auth.currentUser);
  } catch (error) {
    const error_message = { code: error.code, message: error.message };
    yield put({ type: AuthTypes.REGISTER_FAILURE, registerError: error_message });
  }
}

function* listenOnAuth() {
  const authChannel = eventChannel(emitter =>
    Firebase.auth().onAuthStateChanged(authState => {
      if (authState) {
        emitter(authState);
      } else {
        emitter(false);
      }
    })
  );
  yield takeEvery(authChannel, setUserParameters);
}

function* watchActiveUser(user) {
  
  const userChannel = eventChannel(emitter =>
    user.ref.onSnapshot(emitter)
  );

  yield takeEvery(userChannel, function* f(user) {
    yield put(AuthActions.setUser(user));
  });

  yield take(AuthTypes.LOGOUT_SUCCESS);
  userChannel.close();
}

function* setUserParameters(user) {
  try {
    if (!user) {
      return;
    }

    yield put(AuthActions.setUserAuth(user));

    const dbUser = yield fetchUser(Firebase.firestore().collection(Collections.users).doc(user.uid));

    if (!dbUser.exists) {
      yield call(createFirestoreUser, user);
    }

    yield fork(watchActiveUser, dbUser);

    dbUser.ref.update({
      lastSignInTime: new Date(Date.now())
    });

    yield put({type: CalendarViewTypes.GET_WORDS, user: dbUser})

    yield fork(listenWords);

  } catch (err) {
    console.error(err);
  } finally {
    yield put(AuthActions.setLoading(false));
  }
}

function* loginSaga({email, password}) {
  try {
    yield call(reduxSagaFirebase.auth.signInWithEmailAndPassword, email, password);
  }
  catch(error) {
    const error_message = { code: error.code, message: error.message };
    yield put({ type: AuthTypes.LOGIN_FAILURE, loginError: error_message });
  }
}

function* logout() {
  yield put(AuthActions.setLoading(true));
  yield call([Firebase.auth(), Firebase.auth().signOut]);
  yield(put(AuthActions.logoutSuccess()));
  yield put(AuthActions.setLoading(false));
  yield put(push('/login'));
}

function* fetchSuggestedIds() {
  const { user } = yield select(authSelector);
  let ids = [];
  for (let i = 0; i < 3; i++) {
      ids.push(humanId());
  }

  yield put(AuthActions.setSuggestedIds(ids));
}

function* setPostRegistrationStep() {
  const { user, postRegistrationStep } = yield select(authSelector);
  yield user.ref.update({
    postRegistrationStep
  })
}

function* validateUserName() {

}

function* updateUserName() {

}


export default function* rootSaga() {
  yield fork(listenOnAuth);
  yield all([
    takeEvery(AuthTypes.REGISTER, register),
    takeEvery(AuthTypes.LOGIN_REQUEST, loginSaga),
    takeEvery(AuthTypes.FETCH_SUGGESTED_IDS, fetchSuggestedIds),
    takeEvery(AuthTypes.MICROSOFT_LOGIN_REQUEST, microsoftLoginSaga),
    takeEvery(AuthTypes.GOOGLE_LOGIN_REQUEST, googleLoginSaga),
    takeEvery(AuthTypes.SET_POST_REGISTRATION_STEP, setPostRegistrationStep),
    takeEvery(AuthTypes.VALIDATE_USER_NAME, validateUserName),
    takeEvery(AuthTypes.DELETE_USER, deleteUser),

    takeEvery(AuthTypes.LOGOUT, logout),
  ])
}