var Spellchecker = require("hunspell-spellchecker");

const getAff = async () => {
    const aff =  await fetch(require("./typo-js/dictionaries/en_US/en_US.aff"));
    const t = await aff.text();        
    return t;
}

const getDict = async () => {
    const dic = await fetch(require("./typo-js/dictionaries/en_US/en_US.dic"))
    const d = await dic.text()
    return d;
}

export const fetchDic = () => {
    return new Promise(async (res) => {

        const [aff, dic] = await Promise.all([
            getAff(),
            getDict()
        ])

        var spellchecker = new Spellchecker();
        var DICT = spellchecker.parse({
            aff,
            dic
        });

        spellchecker.use(DICT);

        return res (spellchecker);
    })
}