import React, { useEffect } from 'react'

import { Cell } from './Cell'

export const EmptyRow = ({ solution } : { solution: string} ) => {
  const [emptyCells, setEmptyCells] = React.useState<any>(Array.from(Array(solution.length || 5)));

  useEffect(() => {
    setEmptyCells(Array.from(Array(solution.length || 5)))
  }, [solution])

  console.log()

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_: any, i: any) => (
        <Cell key={i} />
      ))}
    </div>
  )
}
