import { fork } from 'redux-saga/effects';
import AuthSaga from './AuthSaga';
import DomainsSaga from './DomainsSaga';
import WordsSaga from './WorblesSaga';

export default function* root() {
  yield fork(AuthSaga);
  yield fork(DomainsSaga);
  yield fork(WordsSaga);
}
