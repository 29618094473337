import React from 'react'

import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'

type Props = {
  guess: string
  solution: string
}

export const CompletedRow = ({ guess, solution }: Props) => {
  const statuses = getGuessStatuses(guess, solution)

  console.log("GUESS", guess);
  return (
    <div title="completed" className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <Cell key={i} value={letter} status={statuses[i]} />
      ))}
    </div>
  )
}
