import firebase from 'firebase'
import '@firebase/firestore' // 👈 If you're using firestore
import ReduxSagaFirebase from 'redux-saga-firebase'
import FIREBASE_CONFIG from './config.firebase';
import ReactGA from 'react-ga';

require('firebase/auth');

const Firebase = firebase.initializeApp(FIREBASE_CONFIG);


window.Firebase = Firebase;

export default Firebase;
export const reduxSagaFirebase = new ReduxSagaFirebase(Firebase)

const TRACKING_ID = window.location.href.includes("worble-staging") ? "G-XJ05G34XEK" :  "G-XJ05G34XEK";
ReactGA.initialize(TRACKING_ID);

export const storage = firebase.storage();