import React from 'react';
import { ENV, BACKEND } from '../../constants';

const pre = '#f6f8fa'

const Copy = () => <svg class="inline h-4 w-4 hover:text-green-500" style={{fill: '#38a169', marginLeft: '4px', marginBottom: '2px'}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>

const IconButton = () => (
    <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center" style={{position: 'absolute', top: 2, right: 2}}>
    <svg class="inline h-3 w-3 hover:bg-gray-400 "xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
    </button>
)

const Verified = () => (
  <span class="px-1 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
  <svg class="bi bi-check" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z" clip-rule="evenodd"/>
</svg>
        </span>
)

const NotVerified = () => (
  <span class="px-1 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
  <svg class="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"/>
<path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"/>
</svg>
    </span>
)

export default ({domain}) => (
    <div class="flex flex-col">
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
      <table class="min-w-full">
        <thead>
          <tr>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Type
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
             Name
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Enter This Value
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Current Value
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr>
          <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
              {domain.data().currentTxt === domain.data().txt ? <Verified /> : <NotVerified />}
            </td>
            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
              TXT
            </td>
            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-900">
             @
            </td>
            <td class="px-6 whitespace-no-wrap border-b border-gray-200 items-center">
            <pre class="flex flex-row items-center" style={{backgroundColor: pre, position: 'relative', minHeight: 40}}>
            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
              {domain.data().txt}
              </span>
              <IconButton />
              </pre>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
              {domain.data().currentTxt === domain.data().txt ? (
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                {domain.data().currentTxt}
              </span>
              ) : (
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  {domain.data().currentTxt ? domain.data().currentTxt : 'None found'}
              </span>
              )}
            </td>
          </tr>
          {Object.entries(domain.data().aRecords).map(([k,v]) => (
              <tr>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {v === BACKEND[ENV] ? <Verified /> : <NotVerified />}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  A
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-900">
                  {k}
                  </td>
                  <td class="px-6 whitespace-no-wrap border-b border-gray-200 items-center">
                  <pre class="flex flex-row items-center" style={{backgroundColor: pre, position: 'relative', minHeight: 40}}>
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                    {BACKEND[ENV]}
                    </span>
                    <IconButton />
                    </pre>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                    {v === BACKEND[ENV] ? 
                    (
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {v}
                    </span>
                    )
                    : (
                      <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                      {v ? v : 'None found'}
                    </span>
                    )}
                  </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </div>
</div>
)