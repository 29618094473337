import {
  ChartBarIcon,
  InformationCircleIcon,
  LightBulbIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { connect } from "react-redux";
import { Oval } from "react-loader-spinner";

import React, { useState, useEffect } from "react";
import { Alert } from "./components/alerts/Alert";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { AboutModal } from "./components/modals/AboutModal";
import { InfoModal } from "./components/modals/InfoModal";
import { WinModal } from "./components/modals/WinModal";
import { LossModal } from "./components/modals/LossModal";
import { StatsModal } from "./components/modals/StatsModal";
import { DescriptionModal } from "./components/modals/DescriptionModal";
import { PopularModal } from "./components/modals/PopularModal";
import { getWordOfDay, getWords } from "./lib/words";
import { VALIDGUESSES } from "./constants/validGuesses";
import { addStatsForCompletedGame, loadStats } from "./lib/stats";
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from "./lib/localStorage";
import logo from "../../hatch.png";
import worb from "../../worb.png";
import { fetchDic } from "../../components/Dict";
import Firebase from "../../Firebase";
import Collections, { users } from "../../lib/collections";
import { About } from "../../components/Footer";
import { Helmet } from "react-helmet";
import { toISO, setZero } from "../../lib/utils";

function App({ subdomain }: any) {
  const [currentGuess, setCurrentGuess] = useState("");
  const [isGameWon, setIsGameWon] = useState(false);
  const [isWinModalOpen, setIsWinModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [shareComplete, setShareComplete] = useState(false);
  const [words, setWords] = useState<any[]>([]);
  const [solution, setSolution] = useState("");
  const [solutionIndex, setSolutionIndex] = useState(0);
  const [description, setDescripion] = useState("");
  const [nothing, setNothing] = useState("");
  const [loading, setLoading] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [about, setAbout] = useState(false);
  const [similarWorbles, setSimilar] = useState<any[]>([]);
  const [note, setNote] = useState(false);
  const [showSimilarModal, setShowSimilarModal] = useState<boolean>(false);
  const [gameDescription, setGameDescription] = useState("");
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage();
    if (loaded?.solution !== solution) {
      return [];
    }
    const gameWasWon = loaded.guesses.includes(solution);
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true);
    }
    return loaded.guesses;
  });

  const isWordInWordList = (word: string) => {
    return (
      words.includes(word.toUpperCase()) ||
      (window as any).dict.check(word.toUpperCase())
    );
  };

  const getDict = async () => {
    if ((window as any).dict) {
      return false;
    }
    (window as any).dict = await fetchDic();
  };

  useEffect(() => {
    getDict();
  });

  const isWinningWord = (word: string) => {
    return solution === word;
  };

  function hashValue(theString: string, size: number) {
    var sum = 0;
    for (let i = 0; i < theString.length; i++) {
      sum += theString[i].charCodeAt(0) * 3;
    }
    return sum % size;
  }

  const init = async () => {
    setLoading(true);
    const words = (await getWords(subdomain)) || { docs: [] };

    try {
      const user = await Firebase.firestore()
        .collection(Collections.users_public)
        .where("username_lowercase", "==", subdomain)
        .get();
      // const showPopular = user?.docs[0].data
      const { avatar, showSimilar, genre, description } = user?.docs[0].data();
      if (avatar) setAvatar(avatar);
      if (description) setGameDescription(description);
      if (showSimilar) {
        const similar = await Firebase.firestore()
          .collection(Collections.users_public)
          .where("genre", "==", genre)
          .get();

        if (similar.docs.length) {
          // const ranked = similar.docs?.filter((el) => el.data().rank);
          const ranked = similar.docs?.filter((el) => {
            return el.data().rank && el.data().username_lowercase !== subdomain;
          });

          if (ranked.length) {
            const r = [...ranked];
            r.sort((a, b) => a.data().rank - b.data().rank);

            setSimilar(r);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    let { solution, solutionIndex, error, description } = getWordOfDay(words);

    if (error) {
      const today = new Date();
      setZero(today);
      const isoToday = toISO(today);

      solution =
        VALIDGUESSES[
          hashValue(subdomain + isoToday, VALIDGUESSES.length - 1)
        ] || "";

      setNote(true);
      setSolution(solution.toUpperCase());
      setLoading(false);
      return;
    }

    setWords(words.docs.map((d: any) => d.data().word.toUpperCase()));
    setSolution(solution);
    setSolutionIndex(solutionIndex);
    setDescripion(description);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const [stats, setStats] = useState(() => loadStats());

  useEffect(() => {
    if (!solution) return;
    saveGameStateToLocalStorage({ guesses, solution });
  }, [guesses, solution]);

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true);
    }
  }, [isGameWon]);

  const onChar = (value: string) => {
    if (
      currentGuess.length < solution.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (!(currentGuess.length === solution.length) && !isGameLost) {
      setIsNotEnoughLetters(true);
      return setTimeout(() => {
        setIsNotEnoughLetters(false);
      }, 2000);
    }

    if (!isWinningWord(currentGuess) && !isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true);
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false);
      }, 2000);
    }

    const winningWord = isWinningWord(currentGuess);

    if (
      currentGuess.length === solution.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess("");

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length));
        return setIsGameWon(true);
      }

      if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1));
        setIsGameLost(true);
      }
    }
  };

  const onBrowse = () => {
    if ((window as any).gtag) (window as any).gtag("event", "browse_similar");
    setShowSimilarModal(true);
  };

  if (!solution)
    return (
      <div className="py-2 sm:py-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div
          className="flex w-80 mx-auto items-center mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ minHeight: 100 }}>
            <img
              alt="avatar"
              style={{
                width: avatar ? 100 : 60,
                borderRadius: "50%",
                marginBottom: "8px",
              }}
              src={avatar ? avatar : logo}
            />
          </div>
        </div>
        <div className="flex justify-center items-center mb-10">
          {loading ? (
            <div
              style={{
                height: "50vh",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Oval color="#579e6e" height={50} width={50} />
            </div>
          ) : (
            <>{subdomain} wordle</>
          )}
        </div>
        {!loading && (
          <div className="text-sm justify-center flex items-center mt-4 text-gray-500">
            <a
              onClick={(e) => {
                e.preventDefault();
                window.open("https://worble.net");
              }}
              className="ml-2 underline text-gray-500 cursor-pointer"
            >
              Want to make your own worble?
            </a>
          </div>
        )}
      </div>
    );

  return (
    <div className="py-2 sm:py-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div
        className="flex w-80 mx-auto items-center mb-2 flex-row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // minHeight: 108
        }}
      >
        <QuestionMarkCircleIcon
          onClick={() => setAbout(true)}
          className="h-7 w-7 mr-4 cursor-pointer"
        />
        <div style={{ minHeight: avatar ? 80 : 72 }}>
          <img
            alt="avatar"
            style={{ width: avatar ? 80 : 60, borderRadius: "50%" }}
            src={avatar ? avatar : logo}
          />
        </div>
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer ml-4"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <div>
        <div
          className="flex items-center justify-center text-sm text-gray-500"
          style={{
            marginBottom: 12,
          }}
        >
          <div className="flex justify-center items-center text-sm text-gray-500">
            <div>
              {gameDescription ? gameDescription : `${subdomain}'s wordle`}{" "}
            </div>
          </div>
        </div>
        <Grid
          guesses={guesses}
          currentGuess={currentGuess}
          solution={solution}
        />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={guesses}
          solution={solution}
        />

        {similarWorbles.length > 0 && (
          <div className="flex justify-center items-center mt-6">
            <button
              style={{ width: 290 }}
              onClick={onBrowse}
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              Recommended Wordles
            </button>
          </div>
        )}

        <div className="flex justify-center items-center mt-4">
          <HomeIcon
            className="h-8 w-8 cursor-pointer font-gray-500"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://worble.net");
            }}
          />
        </div>
        {note ? (
          <div className="flex justify-center items-center mt-4 text-sm text-gray-500">
            <div>{`Today's ${subdomain} wordle was set automatically`} </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className="flex justify-center items-center mt-4 text-sm text-gray-500"
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "8px",
        }}
      >
        <span>
          <a
            target="_blank"
            href="https://worble.net"
            className="underline mr-1"
          >
            worble.net
          </a>
          allows anyone to create custom daily word games inspired by wordle
        </span>{" "}
      </div>
      {/* {!users} */}
      {/* {console.log("USER", user)} */}
      <DescriptionModal
        isOpen={descriptionModalOpen}
        description={description}
        handleClose={() => setDescriptionModalOpen(false)}
      />
      <WinModal
        solution={solution}
        solutionIndex={`${solutionIndex}`}
        isOpen={isWinModalOpen}
        handleClose={() => setIsWinModalOpen(false)}
        guesses={guesses}
        handleShare={() => {
          // setIsWinModalOpen(false);
          setShareComplete(true);
          return setTimeout(() => {
            setShareComplete(false);
          }, 2000);
        }}
      />
      <LossModal
        solution={solution}
        solutionIndex={`${solutionIndex}`}
        isOpen={isGameLost}
        handleClose={() => setIsGameLost(false)}
        guesses={guesses}
        handleShare={() => {
          // setIsWinModalOpen(false);
          setShareComplete(true);
          return setTimeout(() => {
            setShareComplete(false);
          }, 2000);
        }}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        gameStats={stats}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <Alert message="Not enough letters" isOpen={isNotEnoughLetters} />
      <Alert message="Word not found" isOpen={isWordNotFoundAlertOpen} />
      {/* <Alert
        message={`You lost, the word was ${solution}`}
        isOpen={isGameLost}
      /> */}
      <Alert
        message="Game copied to clipboard"
        isOpen={shareComplete}
        variant="success"
      />
      <About
        isOpen={about}
        handleClose={() => setAbout(false)}
        description={description}
      />
      <PopularModal
        isOpen={showSimilarModal}
        handleClose={() => setShowSimilarModal(false)}
        popular={similarWorbles}
      />
    </div>
  );
}

export default App;
