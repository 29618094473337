import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ShareIcon } from "@heroicons/react/solid";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import DomainActions from "../../redux/domains";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CheckDNSStatus } from "../../lib/enums";
import Calendar from "./calendar";

const InactiveChip = () => (
  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
    Inactive
  </span>
);

const ActiveChip = () => (
  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
    Active
  </span>
);

const Domains = ({ user, domains, push, pathname, domainActions }) => {
  const [link, setLink] = React.useState("");
  const [showFlash, setShowFlash] = React.useState(false);

  useEffect(() => {
    if (user?.data().username_lowercase)
      setLink(
        `${window.location.protocol}//${user.data().username_lowercase}.${
          window.location.host
        }`
      );
  }, [user]);
  return (
    <>
      <header>
      {showFlash && (
          <div class="bg-indigo-800 text-center py-4 lg:px-4">
          <div class="p-2 bg-indigo-600 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span class="font-semibold mr-2 flex-auto">Copied your Worble link to the clipboard</span>
          </div>
        </div>
          )}
        <div
          class="max-w-7xl mx-auto pb-4 h-48 flex justify-center items-center bg-blue-700"
          style={{
            backgroundColor: "#3bb78f",
            backgroundImage: "linear-gradient(315deg, rgb(104 221 183) 0%, rgb(11, 171, 100) 74%)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
          <button onClick={() => window.open(link)} className="button-secondary shadow-lg bg-green-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
            <span className={"ml-2"}>View My Worble</span>
          </button>
          <span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(link)
                setShowFlash(true);
                setTimeout(() => {
                  setShowFlash(false)
                }, 3000);
              }}
              className="mt-4 button-secondary bg-green-700 shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
              </svg>
              <span className={"ml-2"}>Share Link</span>
            </button>
          </span>
          </div>
        </div>
      </header>
      <main>
        <div class="flex flex-col shadow-lg rounded-lg">
          <div className="m-4">
            <Calendar />
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  domains: state.domains.domains,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  domainActions: bindActionCreators(DomainActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Domains);
