import React, { useEffect, useState } from 'react';
import VerifyDNSTable from './VerifyDNSTable';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputMask from 'react-input-mask';
import validate from 'validate.js';
import DomainActions from '../../redux/domains';

//#38a169
const LinkOut = () => (
    <svg class="inline h-4 w-4 hover:text-green-500" style={{fill: '#38a169', marginLeft: '4px', marginBottom: '2px'}} xmlns="http://www.w3.org/2000/svg"viewBox="0 0 48 48"><path d="M38 38H10V10h14V6H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V24h-4v14zM28 6v4h7.17L15.51 29.66l2.83 2.83L38 12.83V20h4V6H28z"/></svg>
)

const Create = ({pathname, activeDomain, domainActions, error}) => {

    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
      domainName: '',
      defaultSway: ''
    })
    const [validationError, setValidationError] = useState('');

    const handleSupport = () => {
        try {
            (window).Tawk_API.maximize();
        } catch (err) {
            console.error('Unable to open Tawk API');
        }
    }

    const handleSave = async (e) => {
      e.preventDefault();
      const notValid = [state.domainName, state.defaultSway].map(url => {
          return validate({website: 'https://' + url}, {website: {url: true}})
      })
      .filter(Boolean);

      if (notValid.length) {
          return setValidationError('Not all URLs are valid')
      }
      domainActions.createDomain(state.domainName.replace('https://', '').replace('www.', ''), state.defaultSway.replace('https://', ''))
    }

    const handleChange = (k, v) => {
      if (validationError) setValidationError('');
      if (error) DomainActions.createDomainFailure('');
      setState({
        ...state,
        [k]: v
      })
    }


    const {
      params: { id }
    } = pathname.includes('edit') ? matchPath(pathname, {
      path: '/domains/:id/edit/',
      exact: true,
      strict: false
    }) || { params: {} }  :{ params: {} }
  

    useEffect((e) => {
      if (id) {
        domainActions.setActiveDomain(null);
        domainActions.getActiveDomain(id);
      }
    },[pathname])

    return (
        <>
  <main>
        <div>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-4">
              <h3 class="text-lg font-medium leading-6 text-gray-900">1. Add your domain</h3>
              <p class="mt-1 text-sm leading-5 text-gray-600">
                Only Add Domains You Own
              </p>
              <p class="mt-1 text-sm leading-5 text-gray-600">
              You will need to update your DNS records to verify that you are an authorized sender for this domain.
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-5 bg-white sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label for="company_website" class="block text-sm font-medium leading-5 text-gray-700">
                        Domain Name
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm text-sm leading-5 text-gray-500">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          https://
                        </span>
                        <input value={state.domainName.replace('https://', '')} onChange={(e) => handleChange('domainName', e.target.value)} id="company_website" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="example.com" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-5 bg-white sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label for="company_website" class="block text-sm font-medium leading-5 text-gray-700">
                        Default Sway
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm text-sm leading-5 text-gray-500">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          https://
                        </span>
                        <InputMask placeholder="sway.office.com/" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" style={{width: '100%'}} onChange={(e) => handleChange('defaultSway', e.target.value)} value={state.defaultSway.replace('https://sway.office.com/', '')} mask="\s\w\a\y\.\o\f\f\i\c\e\.\c\o\m\/****************" maskChar="" />

                        {/* <input onChange={(e) => setDefaultSway(e.target.value)} id="company_website" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="sway.office.com/Template_BusinessPresentation" /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {error && (
                  <p class="px-6 mt-6 text-red-500 text-xs italic">{error}</p>
                  )}
                  {validationError && (
                  <p class="px-6 mt-6 text-red-500 text-xs italic">{validationError}</p>
                  )}
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button onClick={handleSave} class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                      Continue to step 2
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      <div class="hidden sm:block">
        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>
      </div>
      </main>
      </>
    )
}

const mapDispatchToProps = (dispatch) => ({
  domainActions: bindActionCreators(DomainActions, dispatch)
});


const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  activeDomain: state.domains.activeDomain,
  error: state.domains.createDomainFailure
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)