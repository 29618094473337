import { createReducer, createActions } from 'reduxsauce';

export enum CalendarView {
  CALENDAR="Calendar",
  CREATE_WORBLE="Create_Worble",
  EDIT_WORBLE="Edit_Worble"
}

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setCalendarView: ['calendarView', 'defaultDate'],
  setWords: ['words'],

  setWordCreationError: ['wordCreationError'],
  setCreatingWord: ['creatingWord'],
  createWord: ['word', 'note', 'date'],
  editWord: ['word', 'note', 'date', 'deleteWorble'],
  getWords: ['user']
});

export const CalendarViewTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  calendarView: CalendarView.CALENDAR,
  defaultDate: null,
  date: null,
  wordCreationError: '',
  creatingWord: false,
  words: [],
};

export const calendarSelector = (state: any) => state.calendar

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_CALENDAR_VIEW]: (state, { calendarView, defaultDate }) => {
      return { ...state, calendarView, defaultDate }
    },
    [Types.SET_WORD_CREATION_ERROR]: (state, { wordCreationError }) => ({ ...state, wordCreationError }),
    [Types.SET_CREATING_WORD]: (state, { creatingWord }) => ({ ...state, creatingWord }),
    [Types.SET_WORDS]: (state, { words }) => ({ ...state, words }),
    [Types.CREATE_WORD]: (state, { word, note, date}) => ({...state, word, note, date}),
    [Types.EDIT_WORD]: (state, { word, note, date, deleteWorble}) => ({...state, word, note, date}),
    [Types.GET_WORDS]: (state) => ({...state})
});
