import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router'
import useDebounce from "../../components/UseDebounce";
import { Oval } from "react-loader-spinner";
import Hatch from '../../hatch.png'
import { USERNAME_REGEX, validateUserName, saveUsername} from '../../lib/utils';


import Collections from '../../lib/collections';
import { PostRegistrationStep } from "../../sagas/postRegistration";
import AuthActions from '../../redux/auth';
import { BaseModal } from "../../public_routes/worble/components/modals/BaseModal"
import { Verified, NotVerified } from '../../components/FormState';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  user: any;
  authActions: any;
};

// action that creates 3 human readable ids, checks to make sure that none of them exist in the db

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    suggestedIds: state.auth.suggestedIds
}}

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
});

const RefreshButton = ({ onClick } : any) => (
  <button onClick={onClick} className="text-gray-500 font-bold py-2 px-2 rounded-full inline-flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
</svg>
{/* hello */}
</button>
)

export const SettingsModal = ({ isOpen, handleClose, user, authActions, suggestedIds }: any) => {
    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
      authActions.fetchSuggestedIds();
    }, [])
    
    const debouncedUsername = useDebounce(username, 300);

    const vdUsername = async (username: string) => {
      setUsernameValid(false);
      const valid = await validateUserName(username);
      setUsernameValid(valid);
    }

    const handleSave = async (e: any) => {
      e.preventDefault();
      setSaving(true)
      await saveUsername(user, username);
      setSaving(false)
      authActions.setPostRegistrationStep(PostRegistrationStep.CELEBRATE);
    }

    useEffect(() => {
      vdUsername(debouncedUsername);
    }, [debouncedUsername])

    const onRefresh = (e: any) => {
      e.preventDefault();
      authActions.fetchSuggestedIds();
    }
    

  return (
    <BaseModal className="w-4/5" title="Choose Username" isOpen={isOpen} handleClose={() => {}} noClose>
      <div >
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            {/* <MiniGrid guesses={guesses} solution={solution} /> */}
          </div>
        </div>
      </div>
      <div className="mb-2 mt-5 sm:mt-6">
      <form >
          <div className="mt-1 rounded-md shadow-sm mb-6 relative">
            <div className="absolute" style={{ top: 10, right: 8 }}>
                {usernameValid ? <Verified /> : <NotVerified />}
              </div>
            <input value={username} onChange={(e) => {
                let value = e.target.value;
                value = value.replace(USERNAME_REGEX, "");
                setUsername(value)
            }} id="my-user" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
          </div>

        <div className="mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Your unique link</h3>
          <div>
              {username}.worble.net
          </div>
          </div>
  
          <div className="flex-col items-center justify-center">
          <div className="flex items-center justify-between	">
          <div className="text-xs text-gray-500">
            Can't think of one? Use one of these
          </div>
          <RefreshButton onClick={onRefresh} />
          </div>

          <div className="flex-col mb-6">
          {suggestedIds?.map((id: string) => {
            return (
              <div className="cursor-pointer text-sm text-left" onClick={() => setUsername(id)}>{id}</div>
            )
          })}
          </div>
        </div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
          onClick={handleSave}
          disabled={!usernameValid}
          style={{display: 'flex', height: 40, alignItems: 'center'}}
        >
          {saving ? <Oval color="white" height={24} width={24} /> : "Save"}
        </button>
        </form>
      </div>
    </BaseModal>
  );
};

const Celebrate = ({ handleClose, isOpen } : any) => {
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    setSaving(true);
    handleClose();
    setSaving(false);
  }

  return (
    <BaseModal className="w-4/5" title="Welcome to Worble!" isOpen={isOpen} handleClose={handleClose}>
    <div >
      <div className="flex justify-center items-center h-48">
       <img src={Hatch} style={{width: '80px'}} />
      </div>
    </div>
    <div className="font-medium">
      Your Default Avatar
    </div>
    <div className="mt-4 font-medium">
      To upload a custom avatar, visit <b>Settings</b> anytime.
    </div>
    <button
          type="button"
          className="mt-6 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
          onClick={handleSave}
        >
          {saving ? <Oval color="white" height={24} width={24} /> : "Get started"}
        </button>
  </BaseModal>
  )
}

const PostRegistration = ({ user, authActions, suggestedIds } : any) => {
  if (!user || user?.data().postRegistrationStep === PostRegistrationStep.COMPLETE || !user?.data().postRegistrationStep) 
    return null

  const handleUserNameClose = () => {
    authActions.setPostRegistrationStep(PostRegistrationStep.COMPLETE);
  }

  const handleCelebrateClose = () => {
    authActions.setPostRegistrationStep(PostRegistrationStep.COMPLETE);
  }

  if (user?.data()?.postRegistrationStep === PostRegistrationStep.CELEBRATE) {
    return <Celebrate handleClose={handleCelebrateClose} isOpen={true}/>
  }

  return (
    <>
      {user?.data()?.postRegistrationStep === PostRegistrationStep.SET_USERNAME ? (
        <SettingsModal user={user} handleClose={() => handleUserNameClose()} isOpen={true} authActions={authActions} suggestedIds={suggestedIds} />
      ) : <></>}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PostRegistration)