import React, { useEffect } from 'react'

import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'
import Firebase, { reduxSagaFirebase } from '../../../../Firebase';
import { Oval } from "react-loader-spinner";


type Props = {
  isOpen: boolean
  handleClose: () => void
//   gameStats: GameStats
}

export const ForgotPasswordModal = ({ isOpen, handleClose }: Props) => {
    const [email, setEmail] = React.useState('');
    const [err, setErr] =  React.useState('');
    const [loading, setLoading] = React.useState(false);

    const auth = Firebase.auth();

    useEffect(() => {
        if (err) setErr('');
    }, [email])

    const handleClick = async () => {
        try { 
        const result = await auth.sendPasswordResetEmail(email);
        } catch(err) {
            return setErr('Something went wrong. Please try again.')
        }
        setErr("Reset Email sent");
    }


  return (
    <BaseModal title="Forgot Password" isOpen={isOpen} handleClose={handleClose}>
      {/* <StatBar gameStats={gameStats} /> */}
      <h4 className="mt-4 ext-lg leading-6 font-medium text-gray-900">
        Enter email
      </h4>
  
      <input
    onChange={(e) => setEmail(e.target.value)}
    id="email"
    type="email"
    required
    className="mt-6 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />

      <button
        onClick={handleClick}
        disabled={!email}
        type="submit"
        className="mt-4 cursor-pointer w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
    >

{loading ? (
            <Oval color="white" height={24} width={24} />
          ) : (
            "Send Reset Email"
          )}
        </button>
        {err && <div className='text-sm mt-2'>{err}</div>}
    </BaseModal>
  )
}
