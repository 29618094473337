import React, { useState } from 'react';
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux';

import SettingsModal from './modals/SettingsModal';
import AuthActions from '../redux/auth';
import { connect } from 'react-redux'
import Logo from '../logo.png'
import Hatch from "../hatch.png";
import PostRegistration from './postRegistration';
import { BASE_URL } from '../constants';
import {Footer} from '../components/Footer';

const SettingsButton = ({ onClick }) => (
  <button onClick={onClick} className="left-0 hover:bg-gray-200 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center transition ease-in">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  </button>
)

const Frame = ({push, children, authActions, showSettingsModal, user}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const onSettingsClick = (e) => {
    e.preventDefault();
    authActions.showSettingsModal(true)
  }

  return (
    <div className="min-h-screen bg-white">
  <nav className="bg-white border-b border-gray-200">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <div className="flex-shrink-0 flex items-center cursor-pointer" onClick={() => push("/")}>
          <div className="flex -space-x-1 ">
               <img className="inline-block h-8 w-8" src={Hatch} alt=""/>
            </div>

          <span className={`ml-2 font-bold text-gray-900 hover:text-green-700 transition duration-150 ease-in-out cursor-pointer`}>WORBLE</span>
          </div>
          <div className='ml-6' style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
          }}>
          {/* <div onClick={(e) => {e.preventDefault(); push('/domains/create')}} className="hidden md:block text-right"><span className="inline-flex rounded-md shadow-md"><span className="inline-flex rounded-md shadow-xs"><a href="" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white-600 hover:bg-white-500  focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">Add a domain</a></span></span></div> */}
          </div>
        </div>
        <div className="hidden sm:ml-6 sm:flex sm:items-center">

          {/* <!-- Profile dropdown --> */}
          <div className="ml-3 relative">
            <div>
              <SettingsButton onClick={() => setOpenMenu(!openMenu)} />
            </div>
          {openMenu && (
            <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
              <div className="py-1 rounded-md bg-white shadow-xs">
                {/* <a href="#" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Settings</a> */}
                <a href="" onClick={onSettingsClick} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                  Settings
                  </a>
                <a href="" onClick={(e) => {e.preventDefault(); authActions.logout()}} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                  Sign out
                  </a>
              </div>
            </div>
          )}
          </div>
        </div>
        <div className="-mr-2 flex items-center sm:hidden">
          {/* <!-- Mobile menu button --> */}
          <button onClick={() => {
            console.log("MENU")
            setOpenMobileMenu(!openMobileMenu)
          }} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
            {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
            <svg className="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
            <svg className="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    {openMobileMenu && (
        <div >
        <div className="pb-3 border-t border-gray-200">
          <div className="mt-3" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a onClick={onSettingsClick} href="#" className="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">Settings</a>
            <a href="#" onClick={(e) => {e.preventDefault(); authActions.logout()}} className="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">Sign out</a>
          </div>
        </div>
      </div>
    )}
  </nav>
  <SettingsModal user={user} isOpen={showSettingsModal} handleClose={() => authActions.showSettingsModal(false)}/>
  <PostRegistration />
  <div>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
       {children}
      </div>
    </main>
  </div>
  <Footer />
</div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  push: (route) => dispatch(push(route))
});

const mapStateToProps = (state) => ({
  showSettingsModal: state.auth.showSettingsModal,
  user: state.auth.user
})

export default connect(mapStateToProps, mapDispatchToProps)(Frame)