import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  login: ['email', 'password'],

  microsoftLoginRequest: [],
  googleLoginRequest: [],

  loginRequest: ['email', 'password'],
  loginFailure: ['error'],

  register: ['email', 'password'],
  registerSuccess: [''],
  registerFailure: ['error'],

  logout: [],

  setUser: ['user'],
  setUserAuth: ['userAuth'],
  setLoading: ['loading'],

  startAuth: [],
  endAuth: [],

  resetPassword: ['email'],

  logoutSuccess: [],

  setAuthError: ['error'],
  showSettingsModal: ['showSettingsModal'],

  fetchSuggestedIds: [''],
  setSuggestedIds: ['suggestedIds'],
  setPostRegistrationStep: ['postRegistrationStep'],
  validateUserName: ['username'],
  deleteUser: ['']
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  user: null,
  userAuth: null,
  error: null,
  loading: true,
  registerError: null,
  showSettingsModal: false,
  suggestedIds: [],
  postRegistrationStep: null
};

export const authSelector = (state) => state.auth

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.SET_USER]: (state, { user }) => ({
        ...state,
        user
    }),

    [Types.SET_USER_AUTH]: (state, { userAuth }) => ({
        ...state,
        userAuth
      }),

    [Types.SET_LOADING]: (state, { loading }) => ({
        ...state,
        loading
    }),

    [Types.LOGIN_FAILURE]: (state, { loginError }) => ({
      ...state,
      loginError
     }),
    

    [Types.REGISTER_FAILURE]: (state, { registerError }) => ({
      ...state,
      registerError
     }),
    

    [Types.SET_AUTH_ERROR]: (state, { error }) => ({ ...state, error }),

    [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,

    [Types.SHOW_SETTINGS_MODAL]: (state, { showSettingsModal }) => ({ ...state, showSettingsModal }),

    [Types.FETCH_SUGGESTED_IDS]: (state) => ({ ...state }),

    [Types.SET_SUGGESTED_IDS]: (state, { suggestedIds }) => {
      return { ...state, suggestedIds: [...suggestedIds] }
    },

    [Types.SET_POST_REGISTRATION_STEP]: (state, { postRegistrationStep}) => {
      return { ...state, postRegistrationStep }
    },

    [Types.VALIDATE_USER_NAME]: (state, { username}) => {
      return { ...state, username }
    }

});
