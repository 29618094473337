import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter, push } from 'connected-react-router'
import { connect } from 'react-redux';
import configureStore, { history } from './redux/configureStore'
import LoadingState from './components/LoadingState';
import LogRocket from 'logrocket';
import { Helmet } from "react-helmet";


import App from './App';
import Login from './routes/login';
import Home from './routes/home';
import Domains from './routes/domains';
import Create from './routes/domains/create';
import Edit from './routes/domains/edit';
import Frame from './routes/Frame';
import Register from './routes/register';
import Worble from './public_routes/worble/Worble';
import Firebase from './Firebase';

if (!window.location.href.includes('localhost')) {
  // LogRocket.init('l1mtx2/worble');
  LogRocket.init('azdbrx/haydens-project');
}


const store = configureStore()


const mapStateToProps = (state) => ({
  user: state.auth.user,
  userAuth: state.auth.userAuth,
  loading: state.auth.loading,
  initialized: state.auth.initialized
});

const PrivateRoute = connect(mapStateToProps,  { push })(({ render: Component, userAuth, user, loading, push, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (user && !loading) {
      return <Component {...props} />;
    }
    if (!loading && !userAuth) {
      push('/login');
    }
    return <LoadingState />
  }}/>
))

// const Homepage = connect(mapStateToProps)(({render: Component, user, loading, userAuth }) => {
//   // console.log("sldfkjsldkfjsdf", rest);
//   if (loading) return  <LoadingState />

//   return <Route render={(props) => {
//     return <Component user={user} {...props}/>
//   }}/>
// })

const Landing = connect(mapStateToProps,  { push })(({user, userAuth, loading, push}) => {
  if (user && !loading) {
    push('/words');
  } else if(!userAuth && !loading) {
    return <Home />
  }
  return <LoadingState />
})

const getSubdomain = () => {
  const host = window.location.host; // gets the full domain of the app

		const arr = host
			.split(".")
			.slice(0, host.includes("localhost") ? -1 : -2);

      return arr.length > 0 ? arr[0] : null
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const subdomain = getSubdomain()

ReactDOM.render(
  <React.StrictMode>
     {subdomain ? (
      <Helmet>
        <link rel="canonical" href={`https://${subdomain}.worble.net`} />
        <meta property="og:url" content={`https://${subdomain}.worble.net`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${capitalizeFirstLetter(subdomain)} Wordle`} />
        <meta property="og:description" content={`Play daily ${capitalizeFirstLetter(subdomain)} Wordle!`} />
        <meta property="og:image" content="https://worble.net/og.png" />
        <meta property="og:description" content={`Play daily ${subdomain} Wordle`} />
        <title>{`${capitalizeFirstLetter(subdomain)} Wordle`}</title>
      </Helmet>
    ) : (
      <Helmet>
        <link rel="canonical" href={`https://worble.net`} />
        <meta property="og:url" content="https://worble.net" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Custom Wordle - Make and share your own daily word game"
        />
        <meta
          property="og:description"
          content="Custom Wordle - Challenge people with your own wordle inspired game"
        />
        <meta property="og:image" content="https://worble.net/og.png" />
        <meta
          name="description"
          content="Custom Wordle - Challenge people with your own wordle inspired game"
        />
        <title>
          Custom Wordle - Make and share your own daily word game
        </title>
      </Helmet>
    )}
    {subdomain ? (
      <Worble subdomain={subdomain}/>
    ) : (
      <Provider store={store}>
      <ConnectedRouter history={history}>
        <> 
          <Switch>
            <Route exact path="/" render={({user}) =>{
              return <Landing />
            }} />
            <PrivateRoute exact path="/words/create/:date" render={() => <Frame><Domains /> </Frame> } />
            <PrivateRoute exact path="/words/edit/:date" render={() => <Frame><Domains /> </Frame> } />
            <PrivateRoute exact path="/words/:date" render={() => <Frame><Domains /> </Frame> } />
            <PrivateRoute exact path="/words" render={() => <Frame><Domains /> </Frame> } />

            <PrivateRoute exact path="/domains/create" render={() => <Frame><Create /> </Frame> } />
            <PrivateRoute exact path="/domains/:id/edit" render={() => <Frame><Edit /> </Frame> } />
            <Route exact path="/login" render={() => <Login />} />
            <Route exact path="/register" render={() => <Register />} />
          </Switch>
        </>
      </ConnectedRouter>
    </Provider>
    )}
  </React.StrictMode>,
  document.getElementById('root')
);

