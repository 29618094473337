import React from "react";
import { BaseModal } from "../public_routes/worble/components/modals/BaseModal";
import Logo from "../hatch.png";

export const About = ({ isOpen, handleClose, description="" }) => {

  const isSub = () => {
    var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    return domain !== "localhost:3000" && domain !== "worble.net" && domain !== "worble-staging.net"
  }

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose}>
      <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
        <div className="flex justify-center">
          <img style={{ width: 30 }} src={Logo} />
        </div>


        {description && (
          <div>
          <div>
          <div><h3 className="text-xl font-extrabold mt-4">Today's Note</h3></div>
        <div className="mt-2">
            {description}
        </div>
        </div>

        <div class="relative flex items-center mt-4">
        <div class="flex-grow border-t border-gray-400"></div>
        {/* <span class="flex-shrink mx-4 text-gray-500">About</span> */}
        <div class="flex-grow border-t border-gray-400"></div>
        </div>
        </div>
        )}

{/* <div><h3 className="text-xl font-extrabold mt-2">Aboudt</h3></div> */}

        {isSub() ? (
            <div className="mt-4">         
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Worble helps people make custom wordles.
                    <br />
            <a onClick={(e) => {
      e.preventDefault();
      window.open("https://worble.net")
    }} className="underline text-gray-500 cursor-pointer" style={{marginLeft: 4}}>Want to make your own?</a>
          </div>
            </div>
         ) : (
          <div>
            <div className="mt-4">
            Worble is a project by{" "}
            <a
              onClick={() => window.open("https://twitter.com/haydenbetts")}
              className="text-sm text-gray-500 hover:underline  dark:text-gray-400 underline cursor-pointer"
            >
              @haydenbetts
            </a>{" "}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Reach out on twitter or via{" "}
            <a
              className="underline outline-none"
              href="mailto:haydenbetts@gmail.com"
            >
              email
            </a>
            .
          </div>
          </div>
          )}

          {isSub() && (
          <>
        <div><h3 className="text-xl font-extrabold mt-4">How to Play</h3></div>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Guess the WORBLE in 6 tries. After each guess, the color of the
            tiles will change to show how close your guess was to the word.
          </p>
          <div className="flex justify-center mb-1 mt-4">
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-green-500 text-white border-green-500 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              W
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              E
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              A
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              R
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              Y
            </div>
          </div>
          <p className="text-sm text-gray-500">
            The letter W is in the word and in the correct spot.
          </p>
          <div className="flex justify-center mb-1 mt-4">
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              P
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              I
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-yellow-500 text-white border-yellow-500 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              L
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              L
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              S
            </div>
          </div>
          <p className="text-sm text-gray-500">
            The letter L is in the word but in the wrong spot.
          </p>
          <div className="flex justify-center mb-1 mt-4">
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              V
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              A
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              G
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-slate-400 text-white border-slate-400 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              U
            </div>
            <div
              className="border-solid border-2 flex items-center justify-center mx-0.5 text-2xl font-bold rounded column-width bg-white border-slate-200 cell-animation"
              style={{ width: "2.39433rem", height: "2.39433rem" }}
            >
              E
            </div>
          </div>
          <p className="text-sm text-gray-500">
            The letter U is not in the word in any spot.
          </p>
          <br />
        </div>
        </>
          )}
      </div>
    </BaseModal>
  );
};

export const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const onAboutClick = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <div className="p-4 bg-white rounded-lg md:flex md:items-center md:justify-center md:p-6 dark:bg-gray-800 justify-center items-center">
      <ul className="flex flex-wrap items-center justify-center sm:mt-0">
        <li>
          <a
            onClick={onAboutClick}
            className="text-sm text-gray-500 hover:underline dark:text-gray-400 cursor-pointer"
          >
            About
          </a>
        </li>
        {/* <li>
          <a
            href="#"
            onClick={onAboutClick}
            className="mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400"
          >
            Privacy Policy
          </a>
        </li> */}
        <About isOpen={open} handleClose={() => setOpen(false)} />
      </ul>
    </div>
  );
};
