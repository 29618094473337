import { put, takeEvery, all, select, fork, take, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { push } from 'react-router-redux';
import { toISO, setZero } from '../lib/utils';

import Firebase, { reduxSagaFirebase } from '../Firebase';
import Collections from '../lib/collections';
import { authSelector, AuthTypes } from '../redux/auth';
import CalendarActions, { CalendarViewTypes } from '../redux/calendar';


function* createWorble(params) {
  const { word, note, date } = params;
    if (!word || !date) return;

    yield put(CalendarActions.setCreatingWord(true));

    const { user } = yield select(authSelector);

    const existing = yield Firebase.firestore().collection(Collections.words)
        .where('date', '==', setZero(date))
        .where('user', '==', user.ref)
        .get();
    
    if (existing?.docs[0]) {
        yield existing.docs[0].ref.update({
          word,
          note,
          date_str: toISO(setZero(date))
        })
    } else {
      yield Firebase.firestore().collection(Collections.words).add({
        word,
        date: setZero(date),
        date_str: toISO(setZero(date)),
        note,
        user: user.ref,
      })
    }

    const words = yield Firebase.firestore().collection(Collections.words)
      .where('user', '==', user.ref).get();

    yield put(CalendarActions.setWords(words?.docs || []));  

    yield put(push(`/words/${toISO(date)}`));

    yield put(CalendarActions.setCreatingWord(false));
}


export function* listenOnWords({user}) {
    if (!user) return;

    const wordsChannel = eventChannel(emitter => Firebase.firestore().collection(Collections.words).where("user", "==", user.ref).onSnapshot(emitter));
    yield takeEvery(wordsChannel, function* f(words) {
      console.log("WORDS", words)
      yield put({type: CalendarViewTypes.SET_WORDS, words: words.docs})
    });
  
    yield take(AuthTypes.LOGOUT);
    wordsChannel.close();
}

export function* listenWords() {
    while (true) {
      if (yield select(authSelector)) return
      const { user } = yield select(authSelector);
      yield call(listenOnWords, user);
    }
  }

export function* getWords({ user }) {
  const words = yield Firebase.firestore().collection(Collections.words).where('user', '==', user.ref).get();
  yield put(CalendarActions.setWords(words?.docs || []));  
}

export function* editWorble(params) {
  const { word, note, date, deleteWorble } = params;

  console.log(word, note, date, deleteWorble)
  if (!word || !date) return;
}

export default function* root() {
    yield all([
      takeEvery(CalendarViewTypes.CREATE_WORD, createWorble),
      takeEvery(CalendarViewTypes.EDIT_WORD, editWorble),
      takeEvery(AuthTypes.SET_USER, listenWords),
      takeEvery(CalendarViewTypes.GET_WORDS, getWords),
    ]);
  }