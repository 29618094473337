import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DomainActions from '../../redux/domains';
import validate from 'validate.js';
import InputMask from 'react-input-mask';
const pre = '#f6f8fa'


const Copy = () => <svg class="inline h-4 w-4 hover:text-green-500" style={{fill: '#38a169', marginLeft: '4px', marginBottom: '2px'}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>

const IconButton = () => (
    <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center" style={{position: 'absolute', top: 2, right: 2}}>
    <svg class="inline h-3 w-3 hover:bg-gray-400 "xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
    </button>
)

const Edit = () => ( 
  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500"  fill="currentColor" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
)

const ValidWrap = ({focus}) => (
  <span onClick={() => document.getElementById(focus).focus()} style={{position: 'absolute', top: '35%', right: '5%', cursor: 'pointer'}}><Edit /></span>
)

const RoutesTable = ({ activeDomain, domainActions }) => {

    const [pathMap, setPathMap] = useState(activeDomain.data().pathMap);
    const [saveError, setSaveError] = useState('');
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setPathMap(activeDomain.data().pathMap)
    }, [activeDomain])

    const handleSave = async (e) => {
        e.preventDefault();
        const notValid = Object.entries(pathMap['@']).map(([k, v]) => {
            return validate({website: v}, {website: {url: true}})
        })
        .filter(Boolean);

        if (notValid.length) {
            return setSaveError('Not all Sways are valid')
        }
        domainActions.updateActiveDomain({pathMap});
    }

    useEffect(() => {
      setCanSave(JSON.stringify(pathMap) !== JSON.stringify(activeDomain.data().pathMap))
    }, [pathMap])

    const handleChange = (k, v) => {
        setSaveError('');
        setPathMap({'@': {...pathMap['@'], [k]: v}});
    }

    return ( 
    <div class="flex flex-col">
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <table class="min-w-full">
        <thead>
          <tr>
          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Path
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Sway
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
            {Object.entries(pathMap['@']).map(([k, path]) => (
          <tr>
          <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
           {activeDomain.data().domain}{k}
          </td>
          <td style={{position: 'relative'}} class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                <InputMask id="input-1" placeholder="https://sway.office.com" style={{width: '100%', cursor: 'pointer'}} onChange={(e) => handleChange(k, e.target.value)} value={path.replace('https://sway.office.com/', '')} mask="https://\s\w\a\y\.\o\f\f\i\c\e\.\c\o\m\/****************" maskChar="" />
                <ValidWrap focus="input-1" />
              {/* <input style={{width: '100%'}} onChange={(e) => handleChange(k, e.target.value)} value={'https://sway.office.com/' + path}></input> */}
          </td>
        </tr>
        ))}
        </tbody>
      </table>

      {saveError && (
          <p class="mt-6 text-red-500 text-xs italic px-6">{saveError}</p>
        )}

<div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span class={`inline-flex rounded-md ${canSave ? 'shadow-sm' :''}`}>
                    <button style={{visibility: canSave ? 'visible' : 'hidden'}} disabled={!canSave} onClick={handleSave} class={`inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md ${canSave ? 'text-white' : 'text-gray-500'} ${canSave ? 'focus:outline-none bg-green-600 hover:bg-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-700' : ''} transition duration-150 ease-in-out`}>
                      {canSave ? 'Save' : 'Changes Saved'}
                    </button>
                  </span>
                </div>
  </div>
</div>
)
}

const mapDispatchToProps = (dispatch) => ({
    domainActions: bindActionCreators(DomainActions, dispatch)
});  
  
export default connect(null, mapDispatchToProps)(RoutesTable)