import React from "react";
import { push } from "connected-react-router";
import Worble from "./worble.png";
import Hatch from "../../hatch.png";
// import HP from "../../Rectangle_3.png";
import HP from "../../Rectangle_5.png";

import "./home.css";
import { Footer } from "../../components/Footer";

import Logo from "../../logo.png";

import { BASE_URL } from "../../constants";

export default () => {
  const handleSupport = (e) => {
    e.preventDefault();
    try {
      window.Tawk_API.maximize();
    } catch (err) {
      console.error("Unable to open Tawk API");
    }
  };

  return (
    <div
      class="relative bg-white overflow-hidden"
      style={{
        // backgroundColor: '#3bb78f',
        // backgroundImage: 'inear-gradient(315deg, #3bb78f 0%, #0bab64 74%)'
        "--tw-bg-opacity": 1,
        "background-color": "rgb(249 250 251 / var(--tw-bg-opacity))",
      }}
    >
      {/* <div class="bg-indigo-900 text-center py-4 lg:px-4">
  <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">🚨 Alert</span>
    <span class="font-semibold mr-2 text-left flex-auto">Looking for Worb's Worble? Click <a className="underline" href={`${window.location.protocol}//worb.${window.location.host}`} >here.</a></span>
    <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
  </div>
</div> */}
      <div class="relative pt-6">
        <nav class="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
          <div class="flex items-center flex-1">
            <div className="flex-shrink-0 flex items-center cursor-pointer">
              <div className="flex -space-x-1 ">
                <img className="inline-block h-8 w-8" src={Hatch} alt="" />
              </div>

              <span className="ml-2 font-bold text-gray-900 transition duration-150 ease-in-out cursor-pointer text-black">
                WORBLE
              </span>
            </div>
          </div>

          <div class="md:block text-right">
            <span class="inline-flex rounded-md shadow-md">
              <span class="inline-flex rounded-md shadow-xs">
                <a
                  href="/login"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Log in
                </a>
              </span>
            </span>
          </div>
        </nav>

        <div className="pb-16 md:pb-20 lg:pb-24 xl:pb-32">
          <div>
            <div>
              <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div class="sm:text-center lg:text-left">
                  <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl text-center mb-12">
                    <span class="block xl:inline text-gray-800">
                      Make custom wordles
                    </span>
                  </h1>
                  <h2 class="text-xl tracking-tight text-gray-900 sm:text-2xl md:text-2xl text-center mb-6">
                    Get your own unique link to share with friends
                  </h2>
                  <div className="flex flex-row justify-center sm:justify-evenly">
                    <div className="mt-2 mb-12 w-4/5 sm:w-full sm:mt-8 sm:flex sm:justify-center lg:justify-start sm:mt-72 sm:w-96">
                      <div style={{ width: "100%" }}>
                        <div class="rounded-md shadow">
                          <a
                            href="/register"
                            style={{ height: "fit-content" }}
                            class="shadow w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                          >
                            Create account
                          </a>
                        </div>
                        <div class="mt-3">
                          <a
                            href={`${window.location.protocol}//hayden.${window.location.host}`}
                            style={{ height: "fit-content" }}
                            class="shadow w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                          >
                            Live demo
                          </a>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <img
                      className="hidden sm:flex"
                      style={{ width: 350 }}
                      src={HP}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div>
            <div
              className="flex justify-center sm:hidden relative"
              style={{
                margin: "auto",
              }}
            >
              <img
                className="h-100 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                style={{
                  width: "80%",
                }}
                src={HP}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
