import { put, takeEvery, all, select, fork, take, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import DomainActions, { DomainTypes, domainSelector } from '../redux/domains';
import { authSelector, AuthTypes } from '../redux/auth';
import Firebase, { reduxSagaFirebase } from '../Firebase';
import Collections from '../lib/collections'
import { CheckDNSStatus } from '../lib/enums';
import { push } from 'react-router-redux';
import shortid from 'shortid';


function* createDomain({domain, defaultSway}) {
    console.log('domain', domain);
    // check if a domain with this this url already exists
    // if not, create it, and add the sway to www and @
    // sway/path path
    const existing = yield Firebase.firestore().collection(Collections.domains).where('domain', '==', domain).get();
    if (existing.docs.length) {
        yield put(DomainActions.createDomainFailure(`${defaultSway} has already been registered on swaydomains.`))
        return
    }

    const { user } = yield select(authSelector);
    const ref = yield Firebase.firestore().collection(Collections.domains).add({
        domain,
        pathMap: {
            '@': {
                '/*': defaultSway
            }
        },
        aRecords: {
            '@': null,
            'www': null
        },
        user: user.ref,
        txt: `sd-${shortid.generate()}`,
        checkDNSStatus: CheckDNSStatus.active
    })

    yield put(push(`/domains/${ref.id}/edit`));
}

export function* listenOnDomains({user}) {
    if (!user) return;

    const domainsChannel = eventChannel(emitter => Firebase.firestore().collection(Collections.domains).where("user", "==", user.ref).onSnapshot(emitter));
    yield takeEvery(domainsChannel, function* f(domains) {
      yield put({type: DomainTypes.SET_DOMAINS, domains: domains.docs})
    });
  
    yield take(AuthTypes.LOGOUT);
    domainsChannel.close();
}

export function* listenDomains() {
    while (true) {
      if (yield select(authSelector)) return
      const { user } = yield select(authSelector);
      yield call(listenOnDomains, user);
    }
  }

function* getActiveDomain({id}) {
    const domain = yield Firebase.firestore().collection(Collections.domains).doc(id).get();
    yield put({ type: DomainTypes.SET_ACTIVE_DOMAIN, activeDomain: domain });
}

function* updateActiveDomain({update}) {
    const { activeDomain } = yield select(domainSelector);
    yield activeDomain.ref.update({
        ...activeDomain.data(),
        ...update
    })
    const updated = yield activeDomain.ref.get();

    console.log('update', updated.data())
    yield put({ type: DomainTypes.SET_ACTIVE_DOMAIN, activeDomain: updated });
}

function* getDomains({user}) {
    if (!user) return;
    const domains = yield Firebase.firestore().collection(Collections.domains).where('user', '==', user.ref).get();
    yield put({type: DomainTypes.SET_DOMAINS, domains: domains.docs})
}

export default function* root() {
    yield all([
      takeEvery(AuthTypes.SET_USER, listenOnDomains),
      takeEvery(DomainTypes.UPDATE_ACTIVE_DOMAIN, updateActiveDomain),
      takeEvery(DomainTypes.CREATE_DOMAIN, createDomain),
      takeEvery(DomainTypes.GET_ACTIVE_DOMAIN, getActiveDomain),
      takeEvery(DomainTypes.GET_DOMAINS, getDomains)
    ]);
  }