import React, { useState } from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import AuthActions from "../../redux/auth";
import { connect } from "react-redux";
import LoadingState from "../../components/LoadingState";
import Hatch from '../../hatch.png';
import { ForgotPasswordModal } from "../../public_routes/worble/components/modals/ForgotPasswordModal";

const Login = ({ push, authActions, userAuth, loading, loginError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  if (loading) return <LoadingState />;
  if (userAuth) {
    push("/words");
    return <LoadingState />;
  }

  const handleSignIn = (e) => {
    e.preventDefault();
    authActions.loginRequest(email, password);
  };

  return (
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div style={{marginBottom: 64}} class="text-center text-3xl leading-9 font-bold flex justify-center items-center">
            <img className="inline-block h-12 w-12" src={Hatch}></img>

            <span class="ml-2 font-medium text-black transition duration-150 ease-in-out">
            WORBLE
          </span>
        </div>
        <h2 class="mt-4 text-center text-3xl leading-9 font-bold text-gray-900">
          Login
        </h2>
      </div>

      <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <div class="grid grid-cols-1 px-4 sm:px-0">
            <div>
              <span class="w-full inline-flex rounded-md shadow-sm">
                <button
                  onClick={() => authActions.googleLoginRequest()}
                  type="button"
                  class="w-full inline-flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                  aria-label="Sign in with Google"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                      <path
                        fill="#4285F4"
                        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                      />
                      <path
                        fill="#34A853"
                        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                      />
                      <path
                        fill="#EA4335"
                        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                      />
                    </g>
                  </svg>
                  <div className="ml-2">
                  Sign in with Google
                  </div>
                </button>
              </span>
            </div>
            <div>
              {/* <span class="w-full inline-flex rounded-md shadow-sm">
              <button onClick={() => authActions.microsoftLoginRequest()} type="button" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out" aria-label="Sign in with Microsoft">
                <svg class="h-5 h-5" fill="currentColor" viewBox="0 0 20 20">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><path fill="#f3f3f3" d="M0 0h23v23H0z"/><path fill="#f35325" d="M1 1h10v10H1z"/><path fill="#81bc06" d="M12 1h10v10H12z"/><path fill="#05a6f0" d="M1 12h10v10H1z"/><path fill="#ffba08" d="M12 12h10v10H12z"/></svg>                </svg>
              </button>
            </span> */}
            </div>
          </div>
        </div>

        <div class="relative mt-6">
          <div class="absolute inset-0 flex items-center px-4 sm:px-0">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm leading-5">
            <span class="px-2 bg-white text-gray-500">Or continue with</span>
          </div>
        </div>

        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mt-6">
          <form>
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Email address
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  type="email"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div onClick={() => setForgotPasswordModal(true)} className="text-sm hover:underline mt-2 cursor-pointer"> Forgot Password? </div>
            <div class="mt-6">
              <label
                for="password"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Password
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  type="password"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            {loginError && (
              <p class="mt-6 text-red-500 text-xs italic">
                {loginError.message}
              </p>
            )}

            {/* <div class="mt-6 flex items-center justify-end">
              <div class="text-sm leading-5">
                <a
                  href="#"
                  class="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </a>
              </div>
            </div> */}

            <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <button
                  onClick={handleSignIn}
                  disabled={!email || !password}
                  type="submit"
                  class="cursor-pointer w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                >
                  Sign in
                </button>
              </span>
            </div>

            <div className="mt-6">
            <div className="flex justify-center items-center mt-8">
        <h3 className="font-medium text-sm">
          {" "}
          Don't have any account? {" "}
          <a className="text-green-700 font-medium" href="/register">
            Sign up
          </a>
        </h3>
      </div>
            </div>
          </form>
        </div>
        <ForgotPasswordModal isOpen={forgotPasswordModal} handleClose={() => setForgotPasswordModal(false)}/>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authError: state.auth.error,
  userAuth: state.auth.userAuth,
  loading: state.auth.loading,
  loginError: state.auth.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  push: (route) => dispatch(push(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
