import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";


import Firebase, { storage } from ".././../Firebase";
import Collections, { users } from "../../lib/collections";
import AuthActions from "../../redux/auth";
import { BaseModal } from "../../public_routes/worble/components/modals/BaseModal";
import useDebounce from "../../components/UseDebounce";
import { Verified, NotVerified } from "../../components/FormState";
import { Range } from "react-range";

import {
  USERNAME_REGEX,
  validateUserName,
  validateUserNameSync,
  saveUsername,
} from "../../lib/utils";
import hatch from "../../hatch.png";
import { v4 as uuidv4 } from "uuid";
import AvatarEditor from "react-avatar-editor";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  user: any;
};

function getResizedCanvas(canvas: any, newWidth: any, newHeight: any) {
  var tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  var ctx = tmpCanvas.getContext("2d");
  (ctx as any).drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

const DeleteModal = ({isOpen, handleClose, authActions} : any) => {
  const [progress, setProgress] = React.useState(false);
  const handleDelete = async () => {
    setProgress(true);
    authActions.deleteUser();
    await new Promise(resolve => setTimeout(resolve, 1000));
    setProgress(false);
  }

  return (
  <BaseModal title="Delete Account" isOpen={isOpen} handleClose={handleClose}>
  <div>
    <div className="text-sm">
      All your words will be deleted
    </div>
  </div>
  <div className="mt-5 sm:mt-6">
    <button
      type="button"
      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
      onClick={() => {
        handleDelete();
        // shareStatus(guesses, solutionIndex, solution)
        // handleShare()
      }}
    >
      {progress ? (
          <Oval color="white" height={24} width={24} />
        ) : (
          "Delete Account"
      )}
    </button>
  </div>
</BaseModal>
  )
}

const AvatarEditorModal = ({ file, editorRef }: any) => {
  const [scale, setScale] = useState([1.2]);

  if (!file) return null;

  return (
    <div>
      <AvatarEditor
        image={file[0]}
        ref={(ref) => (editorRef.current = ref)}
        width={250}
        height={250}
        border={50}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={scale[0]}
        rotate={0}
        borderRadius={250}
      />
      <div className="relative pt-1">
        <label htmlFor="resize" className="form-label">
          Resize
        </label>

        <div>
          <Range
            step={0.01}
            min={1}
            max={2}
            values={scale}
            onChange={(values) => {
              setScale(values);
            }}
            renderTrack={({ props, children }) => (
              <div {...props} className="h-3 pr-2 my-4 bg-gray-200 rounded-md">
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="w-5 h-5 transform translate-x-10 bg-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const FileUploadButton = ({ file, setFile, editorRef, user }: any) => {
  // const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputFileRef: any = React.useRef();

  const handleFileUpload = (event: any) => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files);
  };

  const handleCropAndSave = (event: any) => {
    setLoading(true);
    const canvas = editorRef.current.getImage();
    const resized = getResizedCanvas(canvas, 256, 256);

    resized.toBlob(async function (blob: any) {
      var image = new Image();

      image.src = blob;

      var uploadTask = await storage
        .ref()
        .child("images/" + uuidv4())
        .put(blob);

      const downloadURL = await uploadTask.ref.getDownloadURL();

      await user.ref.update({
        avatar: downloadURL,
      });

      await Firebase.firestore().collection(Collections.users_public).doc(user.id).update({
        avatar: downloadURL,
        username_lowercase: user.data().username_lowercase
      })

      setFile(null);
    });
    setLoading(false);
  };

  return (
    <React.Fragment>
      <input
        ref={(ref) => (inputFileRef.current = ref)}
        onChange={handleFileChange}
        type="file"
        accept=".png,.jpg"
        style={{ display: "none" }}
        // multiple={false}
      />
      <div className="flex">
        {file ? (
          <>
            <button
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
              onClick={handleCropAndSave}
              disabled={loading}
            >
            {loading ? (
              <Oval color="white" height={24} width={24} />
            ) : (
              "Crop and Save"
            )}
            </button>
            <button
              className="ml-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              onClick={() => setFile(null)}
            >
              {"Revert"}
            </button>
          </>
        ) : (
          <button
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
          onClick={handleFileUpload}
          >
            {"Update Avatar"}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    suggestedIds: state.auth.suggestedIds,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
});

const RefreshButton = ({ onClick }: any) => (
  <button
    onClick={onClick}
    className="text-gray-500 font-bold py-2 px-2 rounded-full inline-flex items-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>
    {/* hello */}
  </button>
);

export const SettingsModal = ({
  isOpen,
  handleClose,
  user,
  authActions,
  suggestedIds,
}: any) => {
  const [username, setUsername] = useState(user.data().username || "");
  const [usernameValid, setUsernameValid] = useState(
    user.data().username ? true : false
  );
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const editorRef: any = React.useRef();

  const handleAvatarEditorClick = () => {};

  useEffect(() => {
    authActions?.fetchSuggestedIds();
  }, []);

  const debouncedUsername = useDebounce(username, 300);

  const vdUsername = async (username: string) => {
    if (username === user.data().username) {
      return setUsernameValid(true);
    }
    setUsernameValid(false);
    const valid = await validateUserName(username, user);
    setUsernameValid(valid);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    setSaving(true);
    await saveUsername(user, username);
    setSaving(false);
  };

  useEffect(() => {
    vdUsername(debouncedUsername);
  }, [debouncedUsername]);

  const onRefresh = (e: any) => {
    e.preventDefault();
    authActions?.fetchSuggestedIds();
  };

  useEffect(() => {
    setUsername(user.data().username);
  }, [user]);

  return (
    <BaseModal
      className="w-4/5"
      title="Settings"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2 flex-col items-center justify-center">
            <div className="flex items-center justify-center mb-4">
              {file ? (
                <AvatarEditorModal file={file} editorRef={editorRef} />
              ) : (
                <img
                  style={{
                    width: user.data().avatar ? 150 : 60,
                    borderRadius: "50%",
                    marginBottom: "8px",
                  }}
                  src={user.data().avatar ? user.data().avatar : hatch}
                ></img>
              )}
            </div>
            <FileUploadButton
              file={file}
              setFile={setFile}
              editorRef={editorRef}
              user={user}
            />
            {/* <MiniGrid guesses={guesses} solution={solution} /> */}
          </div>
        </div>
      </div>
      <div className="mb-2 mt-5 sm:mt-6">
        <h3>Update Username</h3>
        <form>
          <div className="mt-1 rounded-md shadow-sm mb-6 relative">
            <div className="absolute" style={{ top: 8, right: 8 }}>
              {usernameValid ? <Verified /> : <NotVerified />}
            </div>
            <input
              value={username}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replace(USERNAME_REGEX, "");
                setUsername(value);
              }}
              id="username"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>

          <div className="mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Your unique link
            </h3>
            <div>{username}.worble.net</div>
          </div>
          <div className="flex items-center justify-between	"></div>
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            onClick={handleSave}
            disabled={!usernameValid}
            style={{ display: "flex", height: 40, alignItems: "center" }}
          >
            {saving ? (
              <Oval color="white" height={24} width={24} />
            ) : (
              "Save Link"
            )}
          </button>

          <button
            type="button"
            className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm text-black"
            onClick={() => setShowDelete(true)}
            disabled={!usernameValid}
            style={{ display: "flex", height: 40, alignItems: "center" }}
          >
              Delete Account
          </button>
     
        <DeleteModal handleClose={() => setShowDelete(false)} isOpen={showDelete} user={user} authActions={authActions}/>
        </form>
      </div>
    </BaseModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
