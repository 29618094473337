import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createDomain: ['domain', 'defaultSway'],
  createDomainSuccess: [],
  createDomainFailure: ['error'],

  setDomains: ['domains'],
  getDomains: ['uid'],
  
  getActiveDomain: ['id'],
  setActiveDomain: ['activeDomain'],
  updateActiveDomain: ['update']
});

export const DomainTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  domains: [],
  activeDomain: null
};

export const domainSelector = (state) => state.domains

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.SET_DOMAINS]: (state, { domains }) => ({
        ...state,
        domains
    }),
    [Types.CREATE_DOMAIN_FAILURE]: (state, { error }) => ({
        ...state,
        createDomainError: error
    }),
    [Types.SET_ACTIVE_DOMAIN]: (state, { activeDomain }) => ({
        ...state,
        activeDomain
    })

});
