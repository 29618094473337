import React from 'react'
import { BaseModal } from './BaseModal'

export const DescriptionModal = ({
  isOpen,
  description,
  handleClose
}: any) => {
  return (
      <>
      <style>
          {`
          @media (max-width: 600px) {
            .modal {
                min-width: 60%;
                min-height: 50%;
            }
          }`}
      </style>
    <BaseModal className={"modal"} title="Today's Note" isOpen={isOpen} handleClose={handleClose} >
      <div>
            {description}
      </div>
    </BaseModal>
    </>
  )
}
