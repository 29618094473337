import { db } from '../firestore';
import { toISO, setZero } from '../../../lib/utils'

export const getWords = async (subdomain: string) => {
  if (!subdomain) return { docs: [] };
  const snap = await db.collection('users').where('username_lowercase', '==', subdomain).get();
  if (!snap.docs.length) {
    // alert("Something went wrong! Visit https://worble.net for more information.");
    return { docs: [] };
  }

  const words = await db.collection('words')
  .where('user', '==', snap.docs[0].ref)
  .orderBy("date")
  .get();

  return words;
}

export const getWordOfDay = (words: any) => {

  const today = new Date();
  setZero(today);
  const isoToday = toISO(today);
  words.docs.sort((a: any, b: any) => a.data().date.getTime() - b.data().date.getTime())
  const index = words.docs.findIndex((el: any) => {
    if (el.data().date_str) {
      return el.data().date_str === isoToday;
    }
    else {
      return toISO(setZero(el.data().date)) === isoToday
    }
  });

  if (index < 0) return { error: "Emily did not choose a word today :("};
  return { solution: words.docs[index].data().word.toUpperCase(), solutionIndex: index, description: words.docs[index].data().note } 
}