import { combineReducers } from 'redux';

export default (history) => {
  return combineReducers({
    router: require('./router').reducer(history),
    auth:require('../auth').reducer,
    domains: require('../domains').reducer,
    calendar: require('../calendar').reducer
  });
};
