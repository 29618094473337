import FIREBASE_CONFIG from './config.firebase';
import { STAGING, PROD, LOCAL } from './lib/envs';

export const ENV =
  (
    (window.location.host === 'localhost:3000' && STAGING) ||
    FIREBASE_CONFIG.projectId === 'swaydomains' &&
    PROD) || (
    FIREBASE_CONFIG.projectId === 'swaydomains-staging' &&
    STAGING
    )


export const BASE_URL = {
  LOCAL: "http://localhost:3000/",
  STAGING: "https://worble-staging.net/",
  PROD: "https://worble.net"
}
  
export const BACKEND = {
  [PROD]: '23.239.19.4',
  [STAGING]: '45.79.220.253'
};
